import {
  customizationScheme,
  hairColors,
} from "@/services/AvatarConfigService/customizationScheme";

const HairColor = customizationScheme.hairColor.map((color) => {
  const obj = {
    background: {
      type: "color",
      value: hairColors[color],
    },
    value: color,
  };
  return obj;
});

export { HairColor };
