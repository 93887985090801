import React, { useEffect, useState } from "react";

import avatarConfigService from "@/services/AvatarConfigService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import {
  Section,
  Button,
  Checkbox,
  Toggle,
  Background,
  Switch,
  Label,
} from "./styles";

const options = {
  Casual: {
    option1: "Pants",
    option2: "Skirt",
  },
  Formal: {
    option1: "Closed",
    option2: "Open",
  },
};

const AvatarToggle: React.FC = () => {
  const user: TUser | null = userService.getState().ownUser;
  if (!user) return <></>;

  const { avatarConfig, setConfig } = avatarConfigService((state) => ({
    avatarConfig: state.configs.get(user.id),
    setConfig: state.setConfig,
  }));

  const [state, setState] = useState<boolean>(false);
  const [labels, setLabels] = useState(options.Casual);
  const [outfitType, setOutfitType] = useState<string>("Casual");

  useEffect(() => {
    if (avatarConfig && avatarConfig.outfit) {
      const splitted = avatarConfig.outfit.split("_");
      setOutfitType(splitted[2]);
      const status = splitted[1] === "2";
      setState(status);
    }
  }, [avatarConfig]);

  useEffect(() => {
    setLabels(options?.[`${outfitType}`]);
  }, [outfitType]);

  const handleChange = (state: boolean) => {
    const config = Object.assign({}, avatarConfig);
    const splitted = config.outfit.split("_");
    splitted[1] = state ? "2" : "1";
    config.outfit = splitted.join("_");
    setConfig(config);
  };

  return (
    <Section>
      <Button type="button" onClick={() => handleChange(false)}>
        {labels.option1}
      </Button>
      <Toggle>
        <Checkbox
          type="checkbox"
          name={"personlity"}
          id={"personlity"}
          checked={state}
          onChange={() => handleChange(!state)}
        />
        <Label htmlFor={"personlity"}>
          <Background />
          <Switch />
        </Label>
      </Toggle>
      <Button type="button" onClick={() => handleChange(true)}>
        {labels.option2}
      </Button>
    </Section>
  );
};

export default AvatarToggle;
