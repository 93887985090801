import React, { useEffect, useState } from "react";

import AvatarOutfit from "./AvatarOutfit";
import AvatarSection from "./AvatarSection";
import AvatarToggle from "./AvatarToggle";

import { Accessories } from "@/components/Dom/AvatarConfigurator/AvatarAccessories";
import { HairColor } from "@/components/Dom/AvatarConfigurator/AvatarHairColor";
import { HairStyle } from "@/components/Dom/AvatarConfigurator/AvatarHairStyle";
import { Outfits } from "@/components/Dom/AvatarConfigurator/AvatarOutfits";
import { SkinTone } from "@/components/Dom/AvatarConfigurator/AvatarSkinTone";

import avatarConfigService from "@/services/AvatarConfigService";
import { getSkin } from "@/services/AvatarConfigService/customizationScheme";
import debugService from "@/services/DebugService";
import discoverQuestService from "@/services/DiscoverQuestService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { AvatarForm, Title } from "./styles";

import AccessoriesIcon from "~/public/assets/avatar-configurator/Accessories.svg";
import Hair from "~/public/assets/avatar-configurator/Hair.svg";
import Outfit from "~/public/assets/avatar-configurator/Outfit.svg";
import Skin from "~/public/assets/avatar-configurator/Skin.svg";

type TAvatarConfigurator = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  title?: string;
  subtitle?: string;
}

const AvatarConfigurator: React.FC<IProps & TAvatarConfigurator> = ({
  title = "Change your look:",
}) => {
  const [outfitName, setOutfitName] = useState<string>("Casual");
  const [outfitSid, setOutfitSid] = useState<string>("1");

  const user: TUser | null = userService.getState().ownUser;
  if (!user) return <></>;

  const avatarConfig = avatarConfigService((state) => {
    return state.configs.get(user.id);
  });
  const [accessories, setAccessories] = useState<
    {
      background: {
        type: string;
        value: string;
      };
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (!avatarConfig?.outfit) return;

    const outfitParts: Array<string> = avatarConfig.outfit.split("_");

    const outfitName = outfitParts[2];
    setOutfitName(outfitName);

    const outfitSid = outfitParts[1];
    setOutfitSid(outfitSid);

    const setFilteredAccessories = async () => {
      let filteredAccessories = Accessories(outfitSid);

      try {
        // filter out extras that are limited to completed quests
        const achievements = await discoverQuestService
          .getState()
          .getUserAchievements(user.id);

        const hasMemeGlasses = Boolean(
          achievements.find(({ name }) => name === "Talent Tasks")
        );

        if (!hasMemeGlasses)
          filteredAccessories = filteredAccessories.filter(
            ({ value }) => value !== "Extra_0_Glasses_Meme"
          );
      } catch (error) {
        debugService
          .getState()
          .logError(
            `AvatarSection::filterAvatarConfig(): Failed to get user achievements for extras filter!`
          );
      }

      setAccessories(filteredAccessories);
    };

    setFilteredAccessories();
  }, [avatarConfig]);

  useEffect(() => {
    if (!avatarConfig) return;

    // change the skin type when personality changes.
    const config = {
      ...avatarConfig,
      skin: getSkin(outfitName, outfitSid),
    };

    avatarConfigService.getState().setConfig(config);
  }, [outfitSid]);

  return (
    <AvatarForm>
      <div>
        <Title serif>{title}</Title>
      </div>

      <AvatarOutfit />
      <AvatarToggle />

      <AvatarSection
        icon={<Outfit />}
        options={Outfits(outfitName, outfitSid)}
        param="outfitStyle"
        title="Outfit material"
      />

      <AvatarSection
        icon={<Hair />}
        options={HairStyle}
        param="hairCut"
        title="Hair style"
      />

      <AvatarSection
        icon={<Skin />}
        options={SkinTone}
        param="skinColor"
        title="Skin tone"
      />

      <AvatarSection
        icon={<Hair />}
        options={HairColor}
        param="hairColor"
        title="Hair color"
      />

      {accessories.length && (
        <AvatarSection
          icon={<AccessoriesIcon />}
          multiple={true}
          param="extras"
          options={accessories}
          title="Accessories"
        />
      )}
    </AvatarForm>
  );
};

export default AvatarConfigurator;
