import { getOutfitStyles } from "@/services/AvatarConfigService/customizationScheme";

const Outfits = (outfitName: string, outfitSid: string) => {
  return getOutfitStyles(outfitName, outfitSid).map((outfit) => {
    const obj = {
      background: {
        type: "image",
        value: `outfits/${outfit}.png`,
      },
      value: outfit,
    };
    return obj;
  });
};

export { Outfits };
