import React from "react";

import avatarConfigService from "@/services/AvatarConfigService";
import {
  getOutfitStyles,
  getSkin,
  rand,
} from "@/services/AvatarConfigService/customizationScheme";
import { TAvatarConfig } from "@/services/PlayerService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { Fieldset, Label, AestheticOption } from "./styles";

import AvatarBusiness from "~/public/assets/avatar-configurator/AvatarBusiness.svg";
import AvatarCasual from "~/public/assets/avatar-configurator/AvatarCasual.svg";

type TAvatarAesthetic = React.HTMLAttributes<HTMLDivElement>;

interface IProps { }

const AvatarOutfit: React.FC<IProps & TAvatarAesthetic> = () => {
  const user: TUser | null = userService.getState().getOwnUser();
  if (!user) return <></>;

  const avatarConfig = avatarConfigService((state) =>
    state.configs.get(user.id)
  );
  if (!avatarConfig) return <></>;

  const updateOutfit = (event) => {
    const outfit = event.target.value;

    const outfitParts: Array<string> = outfit.split("_");
    const outfitName = outfitParts[2];
    const outfitSid = outfitParts[1];

    const config = {
      ...avatarConfig,
      outfit,
      outfitStyle: rand(getOutfitStyles(outfitName, outfitSid)),
      skin: getSkin(outfitName, outfitSid),
    } as TAvatarConfig;

    avatarConfigService.getState().setConfig(config);
  };

  return (
    <section>
      <Fieldset>
        <Label
          htmlFor="outfit-casual"
          data-selected={avatarConfig.outfit.includes("Casual")}
        >
          <AestheticOption>
            <AvatarCasual />
            <input
              type="radio"
              id="outfit-casual"
              name="outfit"
              value="Outfit_1_Casual"
              onClick={updateOutfit}
            />
            Casual
          </AestheticOption>
        </Label>
        <Label
          htmlFor="outfit-business"
          data-selected={avatarConfig.outfit.includes("Formal")}
        >
          <AestheticOption>
            <AvatarBusiness />
            <input
              type="radio"
              id="outfit-business"
              name="outfit"
              value="Outfit_1_Formal"
              onClick={updateOutfit}
            />
            Business
          </AestheticOption>
        </Label>
      </Fieldset>
    </section>
  );
};

export default AvatarOutfit;
