import React from "react";

import { StyledAspectRatio, Ratio, Content } from "./styles";

type TAspectRatio = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  ratio: number;
}

const AspectRatio: React.FC<IProps & TAspectRatio> = ({
  children,
  ratio,
  ...others
}) => {
  const paddingTop = ratio === 0 ? 100 : 100 / ratio;

  return (
    <StyledAspectRatio {...others}>
      <Ratio paddingTop={paddingTop}>
        <Content>{children}</Content>
      </Ratio>
    </StyledAspectRatio>
  );
};

export default AspectRatio;
