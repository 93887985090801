import React, { useEffect, useState } from "react";

import avatarConfigService from "@/services/AvatarConfigService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { toKebabCase } from "@/utils/Text";

import {
  Title,
  Fieldset,
  Label,
  Icon,
  CheckedIndicator,
  Square,
} from "./styles";

import Check from "~/public/assets/icons/Check.svg";

type TAvatarSection = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  icon?: unknown;
  multiple?: boolean;
  param: string;
  title: string;
  options: Array<any>; //TODO Type
}

const AvatarSection: React.FC<IProps & TAvatarSection> = ({
  icon,
  multiple = false,
  param,
  title,
  options,
}) => {
  const user: TUser | null = userService.getState().getOwnUser();

  if (!user) return <></>;

  const { avatarConfig, setConfig } = avatarConfigService((state) => ({
    avatarConfig: state.configs.get(user.id),
    setConfig: state.setConfig,
  }));
  const [selectedOption, setSelectedOption] = useState<string | string[]>();

  useEffect(() => {
    if (!avatarConfig) return;
    setSelectedOption(avatarConfig[param]);
  }, [avatarConfig]);

  useEffect(() => {
    const filterAvatarConfig = async () => {
      if (!avatarConfig || param !== ("extras" || "outfitStyle")) return;
      const { extras, outfit, outfitStyle } = avatarConfig;
      let preSetValue: string | string[] = [];

      if (param === "extras") {
        // remove extras which can't be added to a certain outfit style
        preSetValue = options
          .filter((el) => extras.includes(el.value))
          .map((el) => el.value);
      } else if (param === "outfitStyle") {
        // chooses the first outfit when there's a change of styles
        if (!outfitStyle.includes(outfit)) {
          preSetValue = options[0].value;
        }
      }
      const config = Object.assign(avatarConfig);
      config[`${param}`] = preSetValue;
      setConfig(config);
    };

    filterAvatarConfig();
  }, [options]);

  const handleChange = (e) => {
    const option = e.target.value.replace(" ", "");
    const config = Object.assign({}, avatarConfig);
    let val;

    if (multiple) {
      if (e.target.checked) {
        // fn to allow only one accessories from the same group. for example, double sunglasses
        val = (selectedOption as []).filter(
          (item: string) =>
            !item
              .substring(0, 9)
              .toLowerCase()
              .includes(option.substring(0, 9).toLowerCase())
        );
        val.push(option);
      } else {
        val = (selectedOption as []).filter((item) => item !== option);
      }
    } else val = option;

    config[`${param}`] = val;
    setConfig(config);
    setSelectedOption(val);
  };

  return (
    <section>
      <Title>
        <Icon>{icon}</Icon>
        {title}
      </Title>
      <Fieldset>
        {options.map((opt) => {
          const optionId = `${param}-${toKebabCase(opt.value)}`;

          const isSelected = multiple
            ? Array.isArray(selectedOption) &&
              selectedOption?.includes(opt.value)
            : selectedOption === opt.value.replace(" ", "");

          return (
            <Square key={optionId} data-selected={isSelected} ratio={1}>
              <Label
                htmlFor={optionId}
                bgType={opt.background.type}
                bgValue={opt.background.value}
              >
                <input
                  type={multiple ? "checkbox" : "radio"}
                  id={optionId}
                  name={param}
                  value={opt.value}
                  checked={isSelected}
                  onChange={handleChange}
                />
              </Label>
              <CheckedIndicator>
                <Check />
              </CheckedIndicator>
            </Square>
          );
        })}
      </Fieldset>
    </section>
  );
};

export default AvatarSection;
