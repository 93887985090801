import {
  customizationScheme,
  skinColors,
} from "@/services/AvatarConfigService/customizationScheme";

const SkinTone = customizationScheme.skinColor.map((tone) => {
  const obj = {
    background: {
      type: "color",
      value: skinColors[tone],
    },
    value: tone,
  };
  return obj;
});

export { SkinTone };
