import styled from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

export const Section = styled.div`
  width: 100%;
  background-color: ${colors.grey.lightest.hex};
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
`;

export const Button = styled.button`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${colors.orange.hex};
  margin: 0 0.5rem;
`;

export const Toggle = styled.div`
  position: relative;
  width: 2.8rem;
  display: inline-block;
  vertical-align: middle;
`;

export const Label = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2rem;
`;

export const Background = styled.div`
  width: 100%;
  height: 2rem;
  background-color: ${colors.orange.hex};
`;

export const Switch = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-color: ${colors.white.default};
  position: absolute;
  top: 0.3rem;
  right: 1.1rem;
  border-radius: 1.4rem;
  transition: transform 0.4s ${ease.inOutQuint} 0s;
`;

export const Checkbox = styled.input`
  display: none;
  &:checked {
    + ${Label} {
      ${Switch} {
        transform: translateX(0.8rem);
      }
    }
  }
`;
