import styled from "styled-components";

export const StyledAspectRatio = styled.div`
  display: block;
  overflow: hidden;
`;

export const Ratio = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(props) => props.paddingTop}%;
`;

export const Content = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
