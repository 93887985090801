import { getAccessories } from "@/services/AvatarConfigService/customizationScheme";

const Accessories = (outfitSid: string) => {
  return getAccessories(outfitSid).map((extra) => {
    const obj = {
      background: {
        type: "image",
        value: `accessories/${extra}.png`,
      },
      value: extra,
    };
    return obj;
  });
};

export { Accessories };
