import styled, { css } from "styled-components";

import AspectRatio from "../../Common/AspectRatio";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";
import { MidHeadlineStyles } from "@/styles/typography";

export const Title = styled.h4`
  ${MidHeadlineStyles}

  align-items: center;
  display: flex;
  margin-bottom: -0.4rem;
`;

export const Square = styled(AspectRatio)`
  background-color: ${colors.grey.diffAreas.hex};
  border-radius: 100%;
  overflow: visible;
`;

export const Icon = styled.span`
  color: ${colors.grey.text.hex};
  width: 3.5rem;
`;

export const Fieldset = styled.fieldset`
  border: 0;
  display: grid;
  gap: 1.2rem;
  grid-template: var(--avatar-options-width) / repeat(
      auto-fill,
      var(--avatar-options-width)
    );
  margin: 0;
  padding: 0;
`;

export const Label = styled.label`
  border-radius: 100%;
  display: block;
  height: 100%;
  position: relative;
  transform: scale(1);
  transition: transform 0.4s ${ease.outCubic};
  width: 100%;

  &:before {
    content: "";
    background-color: transparent;
    border-radius: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${({ bgValue }) => {
    if (bgValue === "#FFF")
      return css`
        border: 0.2rem solid ${colors.grey.lighter.hex};
      `;
  }}

  ${({ bgType, bgValue }) => {
    switch (bgType) {
      case "color":
        return css`
          background-color: ${bgValue};
        `;
      case "image":
        return css`
          background-image: url("/assets/avatar-configurator/${bgValue}");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `;
    }
  }}

  ${Hover(css`
    cursor: pointer;
    transform: scale(0.8);
  `)}
`;

export const CheckedIndicator = styled.div`
  align-items: center;
  background-color: ${colors.grey.diffAreas.hex};
  border-radius: 100%;
  bottom: 0;
  color: ${colors.grey.text.hex};
  display: flex;
  height: 2.5rem;
  justify-content: center;
  position: absolute;
  transition: transform 0.3s ${ease.outCubic};
  transform: scale(0);
  right: 0;
  width: 2.5rem;
  pointer-events: none;

  > svg {
    width: 1.1rem;
  }

  [data-selected="true"] & {
    transform: scale(1);
  }
`;
