import { customizationScheme } from "@/services/AvatarConfigService/customizationScheme";

const HairStyle = customizationScheme.hairCut
  .filter((style) => !style.endsWith("Hair")) // filter out hat hair
  .map((style) => {
    const obj = {
      background: {
        type: "image",
        value: `hairstyles/${style}.png`,
      },
      value: style,
    };
    return obj;
  });

export { HairStyle };
