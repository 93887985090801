// From: https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
export const urlRegex = /(https:\/\/[^\s]+)/g;
// replace all urls in text to a markdown format
export const urlify = (text) => {
  return text.replace(urlRegex, (url) => {
    return `[${url}](${url})`;
  });
};

// From: https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-123.php
export const toKebabCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");

export const getInitials = (firstname, lastname) => {
  const isAnonymous = !firstname && !lastname;
  const first = isAnonymous ? "Anonymous" : firstname || "";
  const last = isAnonymous ? "Participant" : lastname || "";

  if (!lastname) return first;
  if (!firstname) return last;

  return `${first[0]}${last[0]}`;
};
