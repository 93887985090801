import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { MidHeadlineStyles } from "@/styles/typography";

export const Fieldset = styled.fieldset`
  ${MidHeadlineStyles};
  align-items: center;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;

  > * {
    width: 50%;
  }
`;

export const Label = styled.label`
  --outline-color: transparent;

  cursor: pointer;

  &:not([data-selected="true"]) {
    ${Hover(
      css`
        --outline-color: ${colors.grey.lighter.hex};
      `
    )}
  }
  &[data-selected="true"] {
    --outline-color: ${colors.tangerine.normal.hex};
  }

  svg {
    display: block;
    width: 7rem;
    margin-bottom: 1rem;
  }
`;

export const AestheticOption = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
